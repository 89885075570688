/* eslint camelcase: 0, no-undef: 0 */
const YMMQualifiers = () => {
  const qualifierKeys = [
    'aspiration',
    'bed_length',
    'body_num_doors',
    'body_type',
    'cylinder_head_type',
    'drive_type',
    'engine_designation',
    'engine_vin',
    'fuel_delivery_sub_type',
    'fuel_deliver_type',
    'fuel_type',
    'mfr_body_code',
    'rear_spring_type',
    'region',
    'transmission_control_type',
    'transmission_mfr_code',
    'transmission_num_speed',
    'valves',
    'wheel_base',
    'bed_type'
  ]

  const qualifierQuestions = [
    'Select Aspiration',
    'Select Bed Length',
    'Select # of Doors',
    'Select Body Type',
    'Select Cylinder Head Type',
    'Select Drive Type',
    'Select Engine Designation',
    'Select Engine VIN',
    'Select Fuel Delivery Sub-Type',
    'Select Fuel Delivery Type',
    'Select Fuel Type',
    'Select Body Code',
    'Select Rear Spring Type',
    'Select Region Name',
    'Select Transmission Control Type',
    'Select Transmission Code',
    'Select # of Transmission Speeds',
    'Select # of Engine Valves',
    'Select Wheelbase',
    'Select Bed Type'
  ]

  // Get Qualifier Options from vehicle data
  const getAspiration = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.engine_configs.forEach(({ aspiration }) => {
        if (aspiration && aspiration.name) {
          const { id, name } = aspiration
          options[id] = name
        }
      })

      return options
    }, {})
  }

  const getBedLength = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.bed_configs.forEach(config => {
        if (config.bed_length) {
          const { id, inches } = config.bed_length
          options[id] = inches
        }
      })

      return options
    }, {})
  }

  const getBodyNumDoors = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.body_style_configs.forEach(config => {
        if (config.body_num_doors) {
          const { id, doors } = config.body_num_doors
          options[id] = doors
        }
      })

      return options
    }, {})
  }

  const getBodyType = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.body_style_configs.forEach(config => {
        if (config.body_type) {
          const { id, name } = config.body_type
          options[id] = name
        }
      })

      return options
    }, {})
  }

  const getCylinderHeadType = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.engine_configs.forEach(({ cylinder_head_type }) => {
        if (cylinder_head_type && cylinder_head_type.name) {
          const { id, name } = cylinder_head_type
          options[id] = name
        }
      })

      return options
    }, {})
  }

  const getDriveType = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.drive_types.forEach(drive_type => {
        if (drive_type && drive_type.name) {
          const { id, name } = drive_type
          options[id] = name
        }
      })

      return options
    }, {})
  }

  const getBedType = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.bed_type.forEach(bed_type => {
        if (bed_type && bed_type.bed_type_name) {
          const { id, bed_type_name } = bed_type
          options[id] = bed_type_name
        }
      })

      return options
    }, {})
  }

  const getEngineDesignation = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.engine_configs.forEach(({ engine_designation }) => {
        if (engine_designation && engine_designation.name) {
          const { id, name } = engine_designation
          options[id] = name
        }
      })

      return options
    }, {})
  }

  const getEngineVin = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.engine_configs.forEach(({ engine_vin }) => {
        if (engine_vin && engine_vin.name) {
          const { id, name } = engine_vin
          options[id] = name
        }
      })

      return options
    }, {})
  }

  const getFuelDeliverySubType = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.engine_configs.forEach(({ fuel_delivery_config }) => {
        if (fuel_delivery_config && fuel_delivery_config.fuel_delivery_sub_type && fuel_delivery_config.fuel_delivery_sub_type.name) {
          const { id, name } = fuel_delivery_config.fuel_delivery_sub_type
          options[id] = name
        }
      })

      return options
    }, {})
  }

  const getFuelDeliveryType = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.engine_configs.forEach(({ fuel_delivery_config }) => {
        if (fuel_delivery_config && fuel_delivery_config.fuel_delivery_type && fuel_delivery_config.fuel_delivery_type.name) {
          const { id, name } = fuel_delivery_config.fuel_delivery_type
          options[id] = name
        }
      })

      return options
    }, {})
  }

  const getFuelType = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.engine_configs.forEach(({ fuel_type }) => {
        if (fuel_type && fuel_type.name) {
          const { id, name } = fuel_type
          options[id] = name
        }
      })

      return options
    }, {})
  }

  const getMfrBodyCode = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.mfr_body_codes.forEach(mfr => {
        if (mfr && mfr.name) {
          const { id, name } = mfr
          options[id] = name
        }
      })

      return options
    }, {})
  }

  const getRearSpringType = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.spring_type_configs.forEach(({ rear_spring_type }) => {
        if (rear_spring_type && rear_spring_type.name) {
          const { id, name } = rear_spring_type
          options[id] = name
        }
      })

      return options
    }, {})
  }

  const getRegion = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      if (vehicle.region) {
        const { id, name } = vehicle.region
        options[id] = name
      }

      return options
    }, {})
  }

  const getTransmissionControlType = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.transmissions.forEach(({ transmission_base }) => {
        if (transmission_base && transmission_base.transmission_control_type && transmission_base.transmission_control_type.name) {
          const { id, name } = transmission_base.transmission_control_type
          options[id] = name
        }
      })

      return options
    }, {})
  }

  const getTransmissionMfrCode = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.transmissions.forEach(({ transmission_mfr_code }) => {
        if (transmission_mfr_code && transmission_mfr_code.name) {
          const { id, name } = transmission_mfr_code
          options[id] = name
        }
      })

      return options
    }, {})
  }

  const getTransmissionNumSpeed = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.transmissions.forEach(({ transmission_base }) => {
        if (transmission_base && transmission_base.transmission_num_speeds && transmission_base.transmission_num_speeds.speeds) {
          const { id, speeds } = transmission_base.transmission_num_speeds
          options[id] = speeds
        }
      })

      return options
    }, {})
  }

  const getValves = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.engine_configs.forEach(({ valve }) => {
        if (valve && valve.count) {
          const { id, count } = valve
          options[id] = count
        }
      })

      return options
    }, {})
  }

  const getWheelBase = (vehicles) => {
    return vehicles.reduce((options, vehicle) => {
      vehicle.wheel_bases.forEach(wheelBase => {
        if (wheelBase && wheelBase.inches) {
          const { id, inches } = wheelBase
          options[id] = inches
        }
      })
      return options
    }, {})
  }

  const getQualifierValueKey = (name) => {
    switch (name) {
      case 'bed_length':
        return 'inches'
      case 'body_num_doors':
        return 'doors'
      case 'transmission_num_speed':
        return 'speeds'
      case 'valves':
        return 'count'
      case 'wheel_base':
        return 'inches'
      case 'bed_type':
        return 'bed_type_name'
    }

    return 'name'
  }

  const getQualifierOptionsForVehicle = (name, vehicles) => {
    try {
      switch (name) {
        case 'aspiration':
          return getAspiration(vehicles)
        case 'bed_length':
          return getBedLength(vehicles)
        case 'body_num_doors':
          return getBodyNumDoors(vehicles)
        case 'body_type':
          return getBodyType(vehicles)
        case 'cylinder_head_type':
          return getCylinderHeadType(vehicles)
        case 'drive_type':
          return getDriveType(vehicles)
        case 'engine_designation':
          return getEngineDesignation(vehicles)
        case 'engine_vin':
          return getEngineVin(vehicles)
        case 'fuel_delivery_sub_type':
          return getFuelDeliverySubType(vehicles)
        case 'fuel_deliver_type':
          return getFuelDeliveryType(vehicles)
        case 'fuel_type':
          return getFuelType(vehicles)
        case 'mfr_body_code':
          return getMfrBodyCode(vehicles)
        case 'rear_spring_type':
          return getRearSpringType(vehicles)
        case 'region':
          return getRegion(vehicles)
        case 'transmission_control_type':
          return getTransmissionControlType(vehicles)
        case 'transmission_mfr_code':
          return getTransmissionMfrCode(vehicles)
        case 'transmission_num_speed':
          return getTransmissionNumSpeed(vehicles)
        case 'valves':
          return getValves(vehicles)
        case 'wheel_base':
          return getWheelBase(vehicles)
        case 'bed_type':
          return getBedType(vehicles)
        default:
          return {}
      }
    } catch (ex) {
      // fallback to home page
      // main occurence is with use of the back arrow resetting qualifier selection without the local data present
      location.href = '/?search-open=true&step=ymm'
    }
  }

  // Create a standardized qualifier option object
  const formatSteps = (qualifiers, parts, vehicles) => {
    const formattedSteps = qualifiers.map(({
      name,
      options
    }, i) => {
      const stepOptions = {
        index: i,
        name: name,
        question: getQualifierQuestion(name),
        options: formatOptions(name, options, parts, vehicles),
        selection: undefined
      }
      return stepOptions
    })

    return formattedSteps
  }

  const getQualifierQuestion = (name) => {
    const index = qualifierKeys.findIndex(item => item === name)

    if (index > -1) {
      return qualifierQuestions[index]
    }

    return ''
  }

  const formatOptions = (name, options, parts, vehicles) => {
    if (Object.keys(options).length > 0) {
      if (parts.some(part => part[name] === null)) { // Part has only null values
        return getQualifierOptionsForVehicle(name, vehicles)
      }
      // Part has only non-null, do not allow alternatives
      return options
    }

    return {}
  }

  // Filter for unique qualifiers on each part
  const filterQualifiers = (parts, vehicle) => {
    const qualifierData = parts.reduce((obj, part) => {
      qualifierKeys.forEach(key => {
        const qualifier = part[key]
        // key = wheel_base
        /* qualifier = [
                        {
                        id: "119",
                        inches: "157.0",
                        cm: "398.8"
                        },
                        {
                        id: "53",
                        inches: "145.0",
                        cm: "368.3"
                        },
                        {
                        id: "75",
                        inches: "163.0",
                        cm: "414.0"
                        }
                      ]
  */
        const isValidQualifier = !!qualifier?.length
        if (isValidQualifier) {
          const keyValue = getQualifierValueKey(key)
          // keyValue = inches
          if (typeof obj[key] === 'undefined') { // get options from vehicle
            const qualifierOptionsFromVehicle = vehicle[0][key]
            /*
              0: {id: '272', inches: '127.8', cm: '324.6'}
              1: {id: '392', inches: '127.4', cm: '323.6'}
              2: {id: '408', inches: '140.6', cm: '357.1'}
            */
            if (qualifierOptionsFromVehicle === 'undefined') { return obj }

            const options = qualifierOptionsFromVehicle.reduce((_obj, option) => {
              const optionId = option.id
              _obj[optionId] = option[keyValue]
              return _obj
            }, {})
            /*
             options =
            {
              272: "127.8"
              392: "127.4"
              408: "140.6"
            }
            */
            obj[key] = options
          }
        }
      })
      return obj
    }, {})
    const formattedQualifiers = Object.keys(qualifierData).map(key => {
      return { name: key, options: qualifierData[key] }
    })

    /*
      for a wheel_base qualifier this will return: array with one object in it
      0:
      index: 0
      name: "wheel_base"
      options: {272: '127.8', 392: '127.4', 408: '140.6'}
      question: "Select Wheelbase"
      selection: undefined
    */
    return formattedQualifiers
  }
  /*
  Gets an array of a single part qualifiers and see if the chosen id is within it
  partQualifiers
    ex: part.wheel_base
  selection
    ex: 53 (the selected option id)
  */
  const partHasSelection = (partQualifiers, selection) => {
    const hasSelection = partQualifiers.findIndex(qualifierOption => qualifierOption.id === selection) !== -1
    return hasSelection
  }
  const filterPartsByQualifier = (parts, name, selection) => {
    return parts.filter(part => {
      // if a part does not have this qualifier, it is valid because it fits all(no specific case)
      if (part[name] == null) return true
      // if there is a qualifier for the part, make sure it contains the selected id
      else { return partHasSelection(part[name], selection) }
    })
  }

  const filterQualifiedParts = (parts, qualifiers) => {
    return qualifiers.reduce((parts, qualifier) => {
      return filterPartsByQualifier(parts, qualifier.name, qualifier.value)
    }, parts)
  }

  const getQualifierSteps = (parts, vehicle) => {
    const qualifiers = filterQualifiers(parts, vehicle)
    if (qualifiers && Object.keys(qualifiers).length > 0) {
      const steps = formatSteps(qualifiers, parts, vehicle)
      return steps
    }
    return []
    // TODO if there is no questions, return empty array []
  }
  return { getQualifierSteps, filterQualifiedParts }
}
export const ymmQualifiers = YMMQualifiers()

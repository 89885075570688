<template>
  <div class="icon-container" :class="container">
          <i class="fas" :class="icon"></i>
        </div>
</template>

<script>

export default {
  props: { container: String, icon: String },
  setup () {
    return { }
  }
}
</script>

<style scoped lang="scss">
.icon-container{
  background-color: var(--btn-bg-color) !important;
  i.fas{
    color: white !important;
  }
}
.icon-container:hover{
  background-color: var(--btn-bg-hover-color) !important;
  i.fas{
    color: white !important;
  }
}
</style>

// eslint-disable-next-line no-unused-vars
import axios from 'axios'
// const API = process.env.NODE_ENV === 'production' ? 'http://localhost:5000/api' : 'http://localhost:5000/api'
const API = process.env.NODE_ENV === 'production' ? 'https://20230112mwmsmaindatasvc.azurewebsites.net/api' : 'https://20230112mwmsmaindatasvc.azurewebsites.net/api'

const getAccessToken = async () => {
  const token = await axios.get(API + '/v2/Auth0/AccessToken').then(res => res.data).catch(async (error) => {
    return error
  })
  return token
}

const requestResource = async (_url) => {
  let errorMessage = false

  var token = localStorage.getItem('accessToken')

  if (token === null || token.includes('Error')) {
    token = await getAccessToken()
    localStorage.setItem('accessToken', token)
  } else {
    const decodedJwt = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
    if (decodedJwt.exp * 1000 <= Date.now()) {
      token = await getAccessToken()
      localStorage.setItem('accessToken', token)
    }
  }
  const bearer = 'Bearer ' + token

  const request = await axios.get(_url, { headers: { Authorization: bearer } }).then(res => res.data).catch(async (error) => {
    errorMessage = error
    return false
  })

  console.log('REQUESTING DATA FROM THIS RESOURCE:::', { URL: _url.replace('https://20230112mwmsmaindatasvc.azurewebsites.net/api/v1', 'https://20230112mwmsmaindatasvc.azurewebsites.net/api/v1'), payload: request, errorMessage })

  return request
}

const getQueryData = (_queryParams, _apiEndpoint, _resourceRequest = requestResource) => {
  // queryString = (string, of query params) or (false, if some query params were missing)
  const queryString = Object.keys(_queryParams).reduce((_str, _queryParam) => {
    const paramValue = _queryParams[_queryParam]
    if (paramValue && _str !== false) { _str += `${_queryParam}=${paramValue}&` } else { _str = false }

    return _str
  }, '')

  if (queryString === false) { return false }

  const apiRequestUrl = _apiEndpoint + queryString
  const apiRequest = _resourceRequest
  // RETURNS object containing the api query url AND the request for that query
  return { apiRequestUrl, apiRequest }
}

// example: https://middlewarewowapi.azurewebsites.net/api/v1/Vehicles/Years
const getYearsQuery = () => getQueryData({}, `${API}/v1/Microsite/Years?`, yearsDataFormater)
const yearsDataFormater = async (_url) => {
  const data = await requestResource(_url)
  const formattedData = data.sort((cur, prev) => {
    if (cur.YearId > prev.YearId) { return 1 } else { return -1 }
  })
  return formattedData
}

// example: https://middlewarewowapi.azurewebsites.net/api/v1/Vehicles/Make/ByYear?year=1981
const getMakesQuery = (_groupState) => getQueryData({
  year: _groupState.Year.value || false
}, `${API}/v1/Microsite/Make/ByYear?`)

// example: https://middlewarewowapi.azurewebsites.net/api/v1/Vehicles/Models/ByYearMake?year=1981&makeID=54
const getModelsQuery = (_groupState) => getQueryData({
  year: _groupState.Year.value || false,
  makeID: _groupState.Make.value || false
}, `${API}/v1/Microsite/Models/ByYearMake?`)

// example: https://middlewarewowapi.azurewebsites.net/api/v1/Vehicles/SubModels/ByYearMakeModel?year=1985&makeID=54&ModelID=666
const getSubModelsQuery = (_groupState) => getQueryData({
  year: _groupState.Year.value || false,
  makeID: _groupState.Make.value || false,
  ModelID: _groupState.Model.value || false
}, `${API}/v1/Microsite/SubModels/ByYearMakeModel?`)

// example: https://middlewarewowapi.azurewebsites.net/api/v1/Vehicles/Engines/ByYearMakeModelSubmodel?year=1985&makeID=54&ModelID=666&SubModelID=20
const getEnginesQuery = (_groupState) => getQueryData({
  year: _groupState.Year.value || false,
  makeID: _groupState.Make.value || false,
  ModelID: _groupState.Model.value || false,
  SubModelId: _groupState.SubModel.value || false
}, `${API}/v1/Microsite/Engines/ByYearMakeModelSubmodel?`, engineDataFormater)

const engineDataFormater = async (_url) => {
  const data = await requestResource(_url)
  const formattedData = data.reduce((_arr, _engine) => {
    const engine = {
      label: `${
                _engine.BlockType
            }${
                _engine.Cylinders
            } ${
                _engine.Liter
            }L ${
                _engine.VehicleTypeGroupName === 'Light Duty' ? '' : _engine.VehicleTypeGroupName
            }`,
      value: _engine.EngineBaseID
    }
    _arr.push(engine)
    return _arr
  }, [])
  return formattedData
}

// https://middlewaremicrositeflatdata20211026.azurewebsites.net/api/v1/Parts/Search?vendor=bre&type=sku&searchvalue=106-0002
const getSearchQuery = (searchValue, type = 'sku', vendor = 'bre') => getQueryData({
  searchValue,
  type,
  vendor
}, `${API}/v1/Parts/Search?`, searchFormatter)

const searchFormatter = async (_url) => {
  const searchedProductData = await requestResource(_url)
  let searchedPart = false
  if (searchedProductData?.Parts.length) {
    searchedPart = searchedProductData.Parts[0]
    searchedPart.sku = searchedPart.sku[0]
    searchedPart.part_type = searchedPart.part_type[0]
  }
  return searchedPart
}

// https://middlewaremicrositeflatdata20211026.azurewebsites.net/api/v1/Vehicles/CompatibleVehicles/ByPartNumber?partNumber=106-0485
const getCompatibleVehiclesQuery = (partNumber) => getQueryData({
  partNumber
}, `${API}/v1/Microsite/CompatibleVehicles/ByPartNumber?`, compatibleVehiclesDataFormatter)

const compatibleVehiclesDataFormatter = async (_url) => {
  const data = await requestResource(_url)
  // use car data to build engine value and then add to car object
  const updatedCPEngines = data.map((car) => {
    const { BlockType, Cylinders, Liter } = car
    const Engine = `${BlockType}${Cylinders} ${Liter}`
    car.Engine = Engine
    return car
  })

  // get our raw data and organize makes into letter object {'f':{letter:'f',data:{'ford':[car1,car2]}}}
  const formattedData = updatedCPEngines.reduce((_obj, _car) => {
    const { Make } = _car
    const makeFirstLetter = Make.charAt(0).toLowerCase()
    const objContainsLetter = (makeFirstLetter in _obj)
    // if we dont have letter in our object, create a letter object for that letter
    if (!objContainsLetter) {
      _obj[makeFirstLetter] = { letter: makeFirstLetter, data: {} }
    }
    // if we dont have that make in that letter object, create that make object
    const letterObjContainsMake = (Make in _obj[makeFirstLetter].data)
    if (!letterObjContainsMake) {
      _obj[makeFirstLetter].data[Make] = []
    }
    _obj[makeFirstLetter].data[Make].push(_car)
    return _obj
  }, {})

  // requests cp data then alphabetize letters and makes within letters
  const alphabetizedCPbyMake = Object.keys(formattedData).reduce((arr, _letterKey) => {
    // get the current letter object wich contains the makes for that letter in 'data'
    const letterObj = formattedData[_letterKey]
    // alphabetize the makes keys within data
    const sortedDataKeys = Object.keys(letterObj.data).sort((cur, prev) => cur.toLowerCase() > prev.toLowerCase() ? 1 : -1)
    // create new data object based on the sorted keys
    const sortedMakes = sortedDataKeys.map((_make) => letterObj.data[_make])
    const sortedData = sortedMakes.map((_makeArr) => {
      return _makeArr.sort((a, b) => {
        // if years are different, put smallest year ahead
        if (a.Year !== b.Year) return a.Year < b.Year ? 1 : -1
        //  if years are same and models are different, put smallest model ahead
        if (a.Year === b.Year && a.Model !== b.Model) return a.Model < b.Model ? -1 : 1
        if (a.Year === b.Year && a.Model === b.Model && a.SubModel !== b.SubModel) return a.SubModel < b.SubModel ? -1 : 1
        if (a.Year === b.Year && a.Model === b.Model && a.SubModel === b.SubModel) return a.Cylinders < b.Cylinders ? -1 : 1

        else return 0
      })
    })
    // update existing data with sorted data
    letterObj.data = sortedData
    // add letterObj with sorted data to our letter array
    arr.push(letterObj)
    // sort letter array with new letter object added
    arr.sort((cur, prev) => cur.letter > prev.letter ? 1 : -1)

    return arr
  }, [])
  return alphabetizedCPbyMake
}

// example: https://middlewarewowapi.azurewebsites.net/api/v1/Vehicles/Engines/ByYearMakeModelSubmodel?year=1985&makeID=54&ModelID=666&SubModelID=20
const getPartsQuery = (_groupState) => getQueryData({
  year: _groupState.Year.value || false,
  makeID: _groupState.Make.value || false,
  ModelID: _groupState.Model.value || false,
  subModelID: _groupState.SubModel.value || false,
  engineID: _groupState.Engine.value || false
}, `${API}/v1/Microsite/Parts/ByYearMakeModelSubmodelEngine?`)

// example: https://middlewaremicrositeflatdata20211026.azurewebsites.net/api/v1/CMS/Environment?forEnv=autozone
const getCMSApiKeyQuery = (forEnv = false) => getQueryData({
  forEnv
}, `${API}/v1/CMS/Environment?`)

// https://20230112mwmsmaindatasvc .azurewebsites.net/api/v1/Vehicles/MakeModel/Search?makeName=Ford&modelSubmodelValues=F-250%20Super%20Duty%20XLT%C2%A0Lariat
const getMakeModelSearchQuery = (makeName, modelSubmodelValues) => getQueryData({
  makeName,
  modelSubmodelValues
}, `${API}/v1/Microsite/MakeModel/Search?`)

const queriesMap = {
  Year: getYearsQuery,
  YearAPI: getYearsQuery,
  Make: getMakesQuery,
  Model: getModelsQuery,
  SubModel: getSubModelsQuery,
  Engine: getEnginesQuery,
  Parts: getPartsQuery,
  CompatibleVehicles: getCompatibleVehiclesQuery,
  Search: getSearchQuery,
  CMSApiKey: getCMSApiKeyQuery,
  MakeModel: getMakeModelSearchQuery
}

const getAPIObjFor = (_componentName) => {
  return queriesMap[_componentName]
}
export {
  getAPIObjFor
}

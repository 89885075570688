<template>
  <div class="dropdown-component">
    <div class="label" style='display:none'>{{ name }}</div>
    <div class="select-container">
      <el-select
        :class="getStatusColorClass(selectedValue)"
        ref="dropdown"
        v-model="selectedValue"
        filterable
        :filter-method="filterMethod"
        :disabled="dropdownDisabled"
        :placeholder="placeholderText"
        :popper-append-to-body="false"
        @visible-change="visibleChange"
        @change="$emit('OPTION_SELECTED', selectedOptionData())"
      >
        <el-option
          v-for="(item, index) in filteredOptions"
          :key="name+index+item+getLabel(item)+getValue(item)"
          :label="getLabel(item)"
          :value="getValue(item)"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, computed, onMounted } from 'vue'
import { getAPIObjFor } from '@/lib/ymm/YMMApi'
import { useStore } from 'vuex'

const STATE_NEEDS_PRIOR_DATA = 'needs-prior-data'
const STATE_LOADING = 'loading'
const STATE_LOADED = 'loaded'

export default defineComponent({
  props: {
    labelKey: String,
    valueKey: { type: String, default: '' },
    name: String,
    next: String
  },

  emits: ['OPTION_SELECTED'],

  setup (props, { emit }) {
    const options = ref([])
    const filteredOptions = ref([])
    const selectedValue = ref('')
    const activeQueryParam = ref('')
    const dropdown = ref(null)
    const dropdownDisabled = ref(true)
    const dropdownState = ref(STATE_NEEDS_PRIOR_DATA)
    const getApiQueryData = getAPIObjFor(props.name)
    const dropdownName = ref('')
    const store = useStore()

    const requestOptionData = async ({ apiRequestUrl, apiRequest }) => {
      dropdownState.value = STATE_LOADING
      activeQueryParam.value = apiRequestUrl
      options.value = filteredOptions.value = await apiRequest(apiRequestUrl)
      dropdownState.value = STATE_LOADED
      dropdownDisabled.value = false
    }

    const getDataNotAvailable = () => {
      return `Selected ${props.name} - No Data Available`
    }
    const dataNotAvailable = getDataNotAvailable()

    const getLabel = (_item) => {
      return _item[props.labelKey]
    }

    const getValue = (_item) => {
      const valueKey = props.valueKey === '' ? props.labelKey : props.valueKey
      return { value: _item[valueKey], label: getLabel(_item) }
    }

    const selectedOptionData = () => {
      const { name } = props
      const { value, label } = selectedValue.value
      const next = ref('')
      if (selectedValue.value === dataNotAvailable) {
        next.value = dataNotAvailable
      } else {
        next.value = props.next
      }
      const selectedData = {
        [`${name}`]: {
          value,
          label,
          next: next.value
        }
      }
      return selectedData
    }

    const placeholderText = computed(() => {
      let text = ''
      if (dropdownState.value === STATE_NEEDS_PRIOR_DATA) {
        text = props.name
      } else if (dropdownState.value === STATE_LOADING) {
        text = 'Loading Options...'
      } else if (dropdownState.value === STATE_LOADED) {
        text = `Select a ${props.name}`
      }
      return text
    })

    const disableDropdown = async () => {
      dropdownState.value = STATE_NEEDS_PRIOR_DATA
      selectedValue.value = ''
      dropdownDisabled.value = true
    }
    // gets called from YMMDropdowns.vue in resetDropdowns and optionSelectedHandler
    const updateSelections = async (_newSelections, _preSelectData = null) => {
      // _newSelections
      const queryData = getApiQueryData(_newSelections)
      if (queryData) {
        await requestOptionData(queryData)
        // if there is pre selected data, autoselect it
        // else if data has only ONE value, autoselect it
        if (_preSelectData) {
          setSelectedValue(_preSelectData)
        } else if (options.value.length === 1) {
          setSelectedValue(getValue(options.value[0]))
        }
      }
    }

    const setSelectedValue = (_value) => {
      const preSelectDataPositon = options.value.find((option) => {
        return getValue(option).label === _value.label
      })
      if (preSelectDataPositon) {
        selectedValue.value = _value
        emit('OPTION_SELECTED', selectedOptionData())
      } else {
        if (store.state.globalYmmModalOpened === true && store.state.globalYmmModalData !== null) {
          // Display dataNotAvailable on the first component load of the serices.  The subsequent components in the series should not display dataNotAvailable.
          if (store.state.displayNoDataAvailable === true) {
            selectedValue.value = dataNotAvailable
          }
          store.commit('switchGlobalYmmModal', { isOpen: true, displayNoDataAvailable: false, payload: store.state.globalYmmModalData })
          emit('OPTION_SELECTED', selectedOptionData())
        }
      }
    }

    const filterMethod = (_val) => {
      filteredOptions.value = options.value.filter((item) => {
        return getLabel(item).toLowerCase().startsWith(_val.toLowerCase())
      })
    }

    const visibleChange = async (_visible) => {
      if (_visible) { filteredOptions.value = options.value }
    }

    function getStatusColorClass (_text) {
      if (_text === dataNotAvailable) {
        return 'data-not-available'
      }
      return {}
    }

    onMounted(() => {
      dropdownName.value = props.name
    })

    return {
      getValue,
      getLabel,
      selectedOptionData,
      disableDropdown,
      updateSelections,
      filterMethod,
      visibleChange,
      options,
      selectedValue,
      placeholderText,
      dropdown,
      dropdownDisabled,
      filteredOptions,
      dropdownName,
      getStatusColorClass
    }
  }
})
</script>

<style lang="scss">
.dropdown-component {
  min-width: 330px;
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 13px;
  .el-input__inner::placeholder{
    color: $mf-font-color-grey-dark !important;
  }
  .el-select-dropdown__item{
    text-align: left;
  }
  .select-container {
    display: flex;
    min-width: 20px;
    flex-grow: 1;
  }

  .el-select {
    width: 100%;
  }

  .data-not-available {
    .el-input__inner {
      border-color: #F26100;
      color: #F26100;
    }
  }
}
</style>
